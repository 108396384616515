// #dialog{data: {'active-class': 'is-active'}}
//   Hello
//
// .a{data:{'dialog-trigger': true, 'target': '#dialog', 'active-class': 'optional-active-class'}}
// .a{data:{'dialog-trigger': true, 'target': '#dialog', 'active-class': 'optional-active-class'}}

import Helper from "../polyfills/helper";
import ErrorHandler from "../polyfills/errors";

window.expanderDatas = [];

const Expander = (function(){

  function create(title, bodyContent, expanderTag = 'div'){
    const id = "dialog_" + new Date().getTime();

    const dialog = document.createElement('div');
    dialog.classList.add('popup');
    dialog.classList.add('backdrop');
    dialog.id = id;
    dialog.dataset.activeClass = 'show'

    const container = document.createElement('div');
    container.classList.add('container');
    dialog.appendChild(container);

    const content = document.createElement('div');
    content.classList.add('content');
    container.appendChild(content)

    const closeIcon = document.createElement('div');
    closeIcon.classList.add('close-icon');
    closeIcon.dataset.dialogTrigger = '1';
    closeIcon.dataset.target = '#' + id;
    closeIcon.dataset.activeClass = 'is-active';
    closeIcon.innerHTML = Helper.icon('close')
    content.appendChild(closeIcon)

    const header = document.createElement('div')
    header.classList.add('header')
    header.innerText = title;
    content.appendChild(header)

    const body = document.createElement('div')
    body.innerHTML = bodyContent;
    content.appendChild(body);

    const footer = document.createElement('div')
    footer.classList.add('footer')
    content.appendChild(footer)

    const arrowDown = document.createElement('div')
    arrowDown.classList.add('arrow-down')
    content.appendChild(arrowDown)

    const triggerElement = document.createElement(expanderTag);
    triggerElement.dataset.dialogTrigger = '1';
    triggerElement.dataset.target = '#' + id;
    triggerElement.dataset.activeClass = 'is-active';

    return {dialog: dialog, triggerElement: triggerElement};
  }

  function resetAll() {
    expanderDatas = []
    init();
  }

  function init(element = null) {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    expanderDatas = [];

    const queryElement = (element == null || element.type == 'ajax::refresh') ? document : element;

    createExpanders(queryElement);
    hookUpExpanders();
  }

  function hookup(element) {
    createExpanders(element);
    hookUpExpanders();
  }

  function createExpanders(queryElement) {
    const _triggerElements = queryElement.querySelectorAll('[data-dialog-trigger]');
    if (_triggerElements == null || _triggerElements.length == 0) {
      return;
    }

    const triggers = Array.from(_triggerElements)
    triggers.forEach(function (triggerElement) {

      let expander = document.querySelector(triggerElement.dataset.target);
      if (expander != null) {
        createExpander(expander, triggerElement)
      } else {
        console.error('expander not found for trigger element')
      }
    });
  }

  function createExpander(expander, triggerElement) {
    const expander_id = expander.id;
    if (Helper.isNull(expander_id)) { return; }

    const elementIndex = expanderDatas.findIndex(e => {
      return e.expander_id == expander_id
    })

    const isActive = expander.dataset.initialState == 'open' || expander.dataset.isActive == '1'

    if (elementIndex < 0) {
      const newTriggerElements = [];
      newTriggerElements.push(triggerElement);
      expanderDatas.push({
        expander_id: expander_id,
        isActive: isActive,
        triggerElements: newTriggerElements,
      });
    } else {
      const existingExpanderData = expanderDatas[elementIndex]
      const existingTriggerElements = existingExpanderData.triggerElements
      existingTriggerElements.push(triggerElement)

      expanderDatas[elementIndex] = {
        expander_id: existingExpanderData.expander_id,
        isActive: existingExpanderData.isActive,
        triggerElements: existingTriggerElements,
      }
    }
  }

  function hookUpExpanders() {
    expanderDatas.forEach(expanderData => {
      expanderData.triggerElements.forEach(triggerElement => {
        triggerElement.removeEventListener('click', onTriggerElementClicked);
        triggerElement.addEventListener('click', onTriggerElementClicked);
      })

      handleElement(expanderData, false)
    })

    document.removeEventListener('click', dismissExpanders)
    document.addEventListener('click', dismissExpanders)
  }

  function onTriggerElementClicked(event) {
    const triggerElement = event.currentTarget;

    let expanderElement = document.querySelector(triggerElement.dataset.target)

    if (expanderElement) {
      const elementIndex = expanderDatas.findIndex(e => {
        return e.expander_id == expanderElement.id
      })

      if (elementIndex < 0) {
        const errorData = {
          triggerElementTarget: triggerElement.dataset.target,
          expanderElement_id: expanderElement.id,
        }
        ErrorHandler.captureException(new Error("elementIndex < 0, data: " + JSON.stringify(errorData)))
      } else {
        handleElement(expanderDatas[elementIndex])
      }
    }
    event.preventDefault()
    event.stopPropagation()
  }

  function handleElement(expanderData, invert = true) {
    let isActive = expanderData.isActive
    if (invert) {
      isActive = !isActive
    }

    handleExpanderData(expanderData, isActive)
  }

  function handleExpanderData(expanderData, isActive) {
    let expanderElement = document.getElementById(expanderData.expander_id);

    if (!expanderElement) { return; }

    if (isActive) {
      handleActive(expanderElement, expanderData)
    } else {
      handleNotActive(expanderElement, expanderData)
    }

    setTriggerElementsStatus(expanderData, isActive)

    const elementIndex = expanderDatas.findIndex(e => {
      return e.expander_id == expanderElement.id
    })

    expanderDatas[elementIndex].isActive = isActive;
  }

  function handleActive(expanderElement, expanderData) {
    expanderElement.classList.add(expanderElement.dataset.activeClass);
    expanderElement.dataset.isActive = '1'

    if (expanderElement.dataset.hideParentOverflow) {
      document.body.style.overflow = 'hidden'
    }

    if (expanderElement.dataset.disableBody) {
      document.body.classList.add('popup-active')
    }

    if (expanderElement.dataset.exclusive == 'true') {
      for (let i = 0; i < expanderDatas.length; i ++) {
        if (expanderDatas[i].expander_id != expanderData.expander_id) {
          handleExpanderData(expanderDatas[i], false)
        }
      }
    }
  }

  function handleNotActive(expanderElement, expanderData) {
    expanderElement.classList.remove(expanderElement.dataset.activeClass);
    expanderElement.dataset.isActive = '0'

    document.body.classList.remove('popup-active')
    if (document.body.style.overflow == 'hidden') {
      document.body.style.overflow = 'auto'
    }
  }

  function setTriggerElementsStatus(expanderData, isActive) {
    expanderData.triggerElements.forEach(trigger => {
      if (trigger.dataset.activeClass) {
        if (isActive) {
          trigger.classList.add(trigger.dataset.activeClass);
        } else {
          trigger.classList.remove(trigger.dataset.activeClass);
        }
      }
    });
  }

  function dismissExpanders(event) {

  }

  function reset(element) {
    const elementIndex = expanderDatas.findIndex(e => {
      return e.expander_id == element.id
    })

    if (elementIndex == -1) {
      element.classList.remove(element.dataset.activeClass);
      element.dataset.isActive = '0'
    } else {
      handleExpanderData(expanderDatas[elementIndex], false)
    }
  }

  function show(element){
    const elementIndex = expanderDatas.findIndex(e => {
      return e.expander_id == element.id
    })

    if (elementIndex < 0) {
      handleActive(element, null)
      return;
    }

    handleExpanderData(expanderDatas[elementIndex], true)
  }

  return {
    init: init,
    reset: reset,
    resetAll: resetAll,
    hookup: hookup,
    show: show,
    create: create,
  }
})();

export {Expander}
