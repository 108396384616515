const Helper = (function() {
  function isEmptyObject(obj) {
    if (! isEmpty(obj.className)) { return false }

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  function isEmpty(obj) {
    return obj == null || obj == undefined || obj == 'undefined' || obj.length == 0 || obj == {}
  }

  function hasDataAttribute(attribute) {
    if (isEmpty(attribute)) { return false; }

    return attribute == '' || attribute.length > 0;
  }

  function guard(entity, _function){
    if (isEmpty(entity)) { return; }
    _function();
  }

  // onEvent('.parent', '.child', 'click', function(){})
  function onEvent(parentSelector, childSelector, event, callback) {
    document.querySelector(parentSelector).addEventListener(event, function(event) {
      if (event.target.matches(childSelector)) {
        callback(event.target);
      }
    });
  }

  function dataSelector(selector, all= false, element = null, ) {
    const parent = element == null ? document : element
    if (all) {
      return parent.querySelectorAll('[data-' + selector + ']')
    } else {
      return parent.querySelector('[data-' + selector + ']')
    }
  }

  function dispatchEvent(eventName, data = {}, source = null, target = null) {
    const event = new CustomEvent('custom::' + eventName, {
      detail: {
        source: source,
        target: target,
        payload: data
      }});
    document.dispatchEvent(event);
  }

  function handleEvent(eventName, callback) {
    document.removeEventListener('custom::' + eventName, callback)
    document.addEventListener('custom::' + eventName, callback)
  }

  function icon(klass) {
    if (klass == 'close') {
      return  '<svg class="bi bi-x" viewBox="0 0 16 16" fill="currentColor" version="1.1" width="1em" height="1em" aria-hidden="true"><path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>'
    } else if (klass == 'help') {
      return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>'
    }

    return '';
  }

  function iconElement(klass){
    const element = document.createElement('div');
    element.innerHTML = icon(klass);
    return element.firstChild;
  }

  function appendElement(target, elementName) {
    const element = document.createElement(elementName);
    target.appendChild(element);
    return element;
  }

  return {
    isEmptyObject: isEmptyObject,
    isEmpty: isEmpty,
    isNull: isEmpty,
    hasDataAttribute: hasDataAttribute,
    guard: guard,
    onEvent: onEvent,
    dataSelector: dataSelector,
    dispatchEvent: dispatchEvent,
    handleEvent: handleEvent,
    icon: icon,
    iconElement: iconElement,
    appendElement: appendElement,
  }
})();

export default Helper
