
import ResponsiveTable from "./responsive-table";
import RemoteForms from "./remote-form";
// import LanguageSwitch from "./language_switch";
// import ThemeSwitch from "./theme_switch";
// import {Slider} from "./slider";
// import {Expander} from "./dialog";
// import CheckBox from "./checkbox"
// import Popup from './popup'
// import AjaxLink from "./ajax-link";
// import FileUpload from "./file-upload";
// import Device from "../pwa/device_manager";
// import LongRefreshLink from "./long_request";
import Switch from "./switch";
import DropDown from "./dropdown";
import PositionIcons from "./position-icons";
import Carousel from './carousel'
// import PushNotificationListener from "./push_notification_listener";
// import FileUploadPlaceholder from "./file_upload_placeholder";
// import Tab from "./tabs";
// import Calendar from "./calendar";
// import {Menu} from "./menu";
// import FileUploadParts from './file-upload-parts'

// import LazyLoader from "./lazy-loaders";

document.addEventListener("DOMContentLoaded", function() {
  // PushNotificationListener.init();
  // Form.init();
  // FileUploadPlaceholder.init()
  // AjaxLink.init();
  // Expander.init();
  ResponsiveTable.init();
  RemoteForms.init()
  // LanguageSwitch.init();
  // ThemeSwitch.init();
  // FileUpload.init();
  // Device.init();
  // LongRefreshLink.init();
  // CheckBox.init();
  // Popup.init();
  PositionIcons.init();
  Switch.init();
  DropDown.init();
  Carousel.init();
  // LazyLoader.init();
  // Tab.init();
  // Calendar.init();
  // Menu.init();
  // FileUploadParts.init();
  // Slider.init((index)=> {
  //   console.log(index)
  //   // window.location = index;
  // })
  //
  // window.AsyncControls.init();
});
