// redirect -> return head :found, x_ajax_redirect_url: client_group_path(@group)
// errors -> return render json: {
//           errors: @post.errors,
//           status: 400
//           html: <<optional html to show in target>>
//         }, status: 400

// normal view -> render partial

import Helper from "../polyfills/helper";
import Form from "./form";

const RemoteForm = (function(){

  let targetElementId = null;

  function targetElement() {
    return document.getElementById(targetElementId)
  }

  function init(asyncContainer) {
    handleAsyncContainer(asyncContainer);
  }

  function handleAsyncContainer(element) {
    setTargetElement(element)

    if (Helper.isEmpty(targetElementId)) { return; }

    initializeAjaxCallback();
  }

  function setTargetElement(element){
    if (element.dataset.target) {
      targetElementId = element.dataset.target;
    } else {
      targetElementId = element.id
    }

    if (Helper.isEmpty(targetElementId)) {
      alert('Target heeft een id nodig')
    }
  }

  function initializeAjaxCallback(){
    const formElement = targetElement();
    formElement.removeEventListener('ajax:success', onAjaxSuccess);
    formElement.addEventListener('ajax:success', onAjaxSuccess);

    formElement.removeEventListener('ajax:complete', onAjaxComplete)
    formElement.addEventListener('ajax:complete', onAjaxComplete)
  }

  function onAjaxSuccess(event){
    setTargetElement(event.currentTarget)

    if (event.detail[2].status === 200) {
      const xhr = event.detail[2];
      const stringData = xhr.response;
      if (stringData.includes("Turbolinks")) { return; }

      if (!xhr.getResponseHeader('Content-Type').includes('application/json')) {
        // Html content
        Form.clearErrors(element());
        handleResponseHtml(xhr.responseText)
        fireAjaxRefreshEvent()
        return;
      }

      //JSON content
      const data = event.detail[0]
      if (!Helper.isEmptyObject(data.errors)) {
        Form.handleErrors(element(), data.errors)
      } else {
        Form.clearErrors(element());
      }

      if (data.html && data.html.length > 1) {
        handleResponseHtml(data.html);
      }

      fireAjaxRefreshEvent()
    }
  }

  function onAjaxComplete(event){
    const responseCode = event.detail[0].status
    const responseText = event.detail[0].responseText;

    setTargetElement(event.currentTarget)

    if (responseCode == 400 && responseText) {
      const responseData = JSON.parse(responseText)

      const event = new CustomEvent('ajax::bad_request', { detail: {errors: responseData.errors} });
      document.dispatchEvent(event);
      Form.handleErrors(element(), responseData.errors)
      fireAjaxRefreshEvent()
    }
    else if (responseCode === 302 || responseCode === 301) {
      let redirect = event.detail[0].getResponseHeader('X-Ajax-Redirect-Url');

      if (Helper.isEmpty(redirect)) {
        redirect = JSON.parse(event.detail[0].response).redirect;
      }

      if (window.location.href.replace('#', '') == window.location.origin + redirect) {
        location.reload();
      } else {
        window.location.href = window.location.origin + redirect;
      }
    }
  }

  function handleResponseHtml(responseHtml){
    const element = targetElement();
    element.innerHTML = responseHtml;
  }

  function fireAjaxRefreshEvent() {
    const event = new CustomEvent('ajax::refresh', {detail: {}});
    document.dispatchEvent(event);
  }

  return {
    init: init
  }
})();

const RemoteForms = (function(){

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    const asyncContainers = document.querySelectorAll('[data-remote]');
    if (asyncContainers == null || asyncContainers.length === 0) { return; }

    Array.from(asyncContainers).forEach((asyncContainer) => {
      RemoteForm.init(asyncContainer);
    })
  }

  return {
    init: init
  }
})();

export default RemoteForms;
