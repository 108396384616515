const PositionIcons = (function(){

  function init(){
    const positionIconElements = Array.from(document.querySelectorAll('[data-expand-icon]'));
    positionIconElements.forEach(element => {
      element.addEventListener('click', onPositionIconClicked)
    })
  }

  function onPositionIconClicked(event) {
    const element = event.currentTarget
    closeOtherIcons(element);
    handlePositionIconClicked(element, !element.classList.contains('is--showing-expanded'))
  }

  function handlePositionIconClicked(element, show){
    const iconGroup = element.closest('.position-icon-wrapper')
    if (show == true) {
      iconGroup.classList.add('is--expanded')
      element.classList.add('is--showing-expanded')
    } else {
      iconGroup.classList.remove('is--expanded')
      element.classList.remove('is--showing-expanded')
    }
  }

  function closeOtherIcons(element){
    const positionIconElements = Array.from(document.querySelectorAll('[data-expand-icon]'));
    positionIconElements.forEach(otherElement => {
      if (element != otherElement) {
        handlePositionIconClicked(otherElement, false)
      }
    });
  }

  return {
    init: init
  }
})();

window.PositionIcons = PositionIcons;

export default PositionIcons;