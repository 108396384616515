import {Expander} from "./dialog";
import CookieStorage from "../polyfills/cookie";

const LanguageSwitch = (function(){
  function init(){
    console.log('language switch init')
    const switchElements = document.querySelectorAll('[data-languages-switch]')
    Array.from(switchElements).forEach(switchElement => {
      console.log('handling switch element')
      switchElement.removeEventListener('change', languageChanged)
      switchElement.addEventListener('change', languageChanged)
    });

    if (!getCookie("language")) {
      console.log('checking language popup')
      const languagePopup = document.getElementById('language_select_popup')
      if (languagePopup) {
        console.log('chowing language popup')
        Expander.show(languagePopup)

        const languageSubmitElement = document.getElementById('languages_initial_submit')
        if (languageSubmitElement) {
          languageSubmitElement.removeEventListener('click', initialLanguageSubmit)
          languageSubmitElement.addEventListener('click', initialLanguageSubmit)
        }
      }
    }
  }

  function languageChanged(event) {
    console.log('languageChanged')
    const selectElement = event.currentTarget;
    const option = selectElement.options[selectElement.selectedIndex];
    console.log(option)
    CookieStorage.setCookie("language", encodeURIComponent(option.value) + "; path=/");
    console.log('reloaading...')
    window.location = 'https://careers.rademaker.com' + '?lng= ' + option.value
    // window.location.reload(true)
  }

  function initialLanguageSubmit(event) {
    const selectElement = document.getElementById('languages_initial_select')
    const option = selectElement.options[selectElement.selectedIndex];
    CookieStorage.setCookie("language", encodeURIComponent(option.value) + "; path=/");
    window.location.reload(true)
  }

  function getCookie(name) {
    return CookieStorage.getCookie(name)
  }

  return {
    init: init
  }
})()

export default LanguageSwitch;
