const Notification = (function () {

  function createToast(text){
    const div = document.createElement('div');
    div.classList.add('toast');
    div.classList.add('toast-bottom');
    div.classList.add('bg-notice');
    div.innerText = text;
    document.body.appendChild(div);

    window.getComputedStyle(div).transform //force layout
    window.setTimeout(_ => {
      document.body.removeChild(div)
    }, 5000);
  }


  function createNotice(text){
    const div = document.createElement('div');
    div.classList.add('notification');
    div.classList.add('bg-notice')
    div.innerText = text;
    document.body.appendChild(div);

    window.getComputedStyle(div).transform
    window.setTimeout(_ => {
      document.body.removeChild(div)
    }, 5000);
  }

  return {
    createToast: createToast,
    createNotice: createNotice
  }

})();

export default Notification
