import Helper from "../polyfills/helper";
import {Expander} from "./dialog";

const Form = (function(){
  function init() {
    // debugger
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    hookUpSubmitButtons();
    hoopUpPasswordInputs();
    hookupBooleans();
  }

  function handleErrors(formElement, errors) {
    clearErrors(formElement);

    if (!errors) { return; }

    for (const [key, value] of Object.entries(errors)) {

      let fieldErrorElement = formElement.querySelector('[data-attribute-name=' + key + ']')
      if (!fieldErrorElement) {
        const sanitizedKey = key.slice(0, -1) + '_ids';
        fieldErrorElement = formElement.querySelector('[data-attribute-name=' + sanitizedKey + ']')
      }
      if (fieldErrorElement) {
        const errorElement = document.createElement('div')
        errorElement.classList.add('error-notification')
        errorElement.classList.add('help')
        errorElement.classList.add('is-danger')
        errorElement.innerText = value
        fieldErrorElement.appendChild(errorElement)
        fieldErrorElement.classList.add('field_with_errors')
      }
    }
  }

  function clearErrors(formElement) {
    let fieldErrorElements = formElement.querySelectorAll('[data-attribute-name]')

    Array.from(fieldErrorElements).forEach(fieldElement => {
      fieldElement.classList.remove('field_with_errors')
    });

    const errorElements = formElement.querySelectorAll('.error-notification')
    errorElements.forEach(errorElement => {
      errorElement.remove();
    })
  }

  function hookUpSubmitButtons() {
    const submitElements = document.querySelectorAll('[data-submit]');
    Array.from(submitElements).forEach(submitElement => {
      submitElement.removeEventListener('click', onSubmit)
      submitElement.addEventListener('click', onSubmit)
    })
  }

  function onSubmit(event) {
    document.querySelector(event.currentTarget.dataset.form).submit()
  }

  function hoopUpPasswordInputs() {
    const hiddenVisiblePasswordToggelers = document.querySelectorAll('[data-toggle-password]')
    Array.from(hiddenVisiblePasswordToggelers).forEach(element => {
      element.removeEventListener('click', onToggle)
      element.addEventListener('click', onToggle)
    })
  }

  function onToggle(event) {
    const hiddenVisiblePasswordToggler = event.currentTarget;
    const parent = hiddenVisiblePasswordToggler.parentElement.parentElement;
    const target = parent.querySelector('input')

    if (target.type == 'password') {
      target.type = 'text';
      parent.classList.add('password_visible');
    } else {
      parent.classList.remove('password_visible');
      target.type = 'password'
    }
  }

  function hookupBooleans() {
    const checkBoxes = document.querySelectorAll('input[type="checkbox"], input[type="radio"]')
    Array.from(checkBoxes).forEach(element => {
      element.removeEventListener('change', onToggleCheckbox)
      element.addEventListener('change', onToggleCheckbox)
      toggleCheckbox(element, true);
    })
  }

  function onToggleCheckbox(event) {
    toggleCheckbox(event.currentTarget)
  }

  function toggleCheckbox(element, firstPass = false){
    const parent = element.closest('.boolean_with_image')
    if (Helper.isNull(parent)) { return; }

    if (element.checked) {
      parent.classList.add('checked')
    } else {
      parent.classList.remove('checked')
    }

    if (element.type == 'radio' && ! firstPass){
      const otherElements = document.querySelectorAll('input[name="user[some_value]"]')
      Array.from(otherElements).forEach(otherElement => {
        if (otherElement != element) {
          if (element.checked) {
            otherElement.closest('.boolean_with_image').classList.remove('checked')
          } else {
            otherElement.closest('.boolean_with_image').classList.add('checked')
          }
        }
      });
    }
  }


  //Input builders
  function addInputGroup(title, subTitle) {
    const inputGroup = document.createElement('div');
    inputGroup.classList.add('input-group');

    if (!Helper.isEmpty(title)) {
      const header = document.createElement('div');
      header.classList.add('input-group-header');
      header.innerText = title;
      inputGroup.appendChild(header);
    }

    const subHeader = document.createElement('div');
    subHeader.classList.add('input-group-sub-header');
    subHeader.innerText = subTitle;
    inputGroup.appendChild(subHeader);

    return inputGroup;
  }

  function addField(inputData) {
    if (inputData.type == 'boolean') {
      return addBooleanField(inputData);
    }

    let input = null;

    const field = addInput(inputData, (field)=>{
      const control = document.createElement('div')
      if (inputData.type == 'select') {
        control.classList.add('select')
      } else {
        control.classList.add('control')
      }

      field.appendChild(control)

      if (inputData.type == 'select'){
        input = document.createElement('select')
        input.classList.add('select')
        input.name = inputData.name;

        const selectedValue = getVariableValue(inputData)
        const option = document.createElement('option')
        option.disabled = true;
        if (Helper.isEmpty(selectedValue)){
          option.selected = true;
        }

        option.innerText = 'Selecteer..';
        input.appendChild(option);

        inputData.values.forEach((key_value)=>{
          const option = document.createElement('option')
          option.value = key_value.key;
          option.innerText = key_value.value;
          if (selectedValue == key_value.key) {
            option.selected = true;
          }
          input.appendChild(option);
        })

      } else {
        input = document.createElement('input')
        input.classList.add('input')
        input.type = inputData.type
        input.name = inputData.name
        input.value = getVariableValue(inputData)
      }

      control.appendChild(input);
    });

    return {field: field, input: input};
  }

  function addInput(inputData, controlFunction){
    const field = document.createElement('div')
    field.classList.add('field')

    const label = document.createElement('label')
    label.classList.add('label')
    label.innerText = inputData.display
    field.appendChild(label)

    controlFunction(field);

    if (inputData.description || inputData.descriptionHTML) {
      field.appendChild(descriptionElement(inputData))
    }

    return field;
  }

  function addBooleanField(inputData){
    const field = document.createElement('div')
    field.classList.add('field')

    const control = document.createElement('div')
    control.classList.add('boolean_with_image')
    field.appendChild(control)

    const input = document.createElement('input')
    input.type = 'checkbox'
    input.value = '1'
    input.label = inputData.display
    input.name = inputData.name
    input.id = "_" + inputData.name
    input.checked = getVariableValue(inputData) == true
    control.appendChild(input);

    const label = document.createElement('label')
    label.classList.add('label')
    label.innerText = inputData.display
    label.setAttribute('for', "_" + inputData.name)
    control.appendChild(label)

    if (inputData.description || inputData.descriptionHTML) {
      control.appendChild(descriptionElement(inputData))
    }

    return {field: field, input: input};
  }

  function descriptionElement(inputData) {
    const description = document.createElement('div')
    description.classList.add('description')

    if (inputData.descriptionHTML) {
      const dialogTrigger = Expander.create(inputData.display,inputData.descriptionHTML, 'span')
      dialogTrigger.triggerElement.classList.add('flex')

      const helpIcon = Helper.iconElement('help')
      helpIcon.classList.add('icon')
      dialogTrigger.triggerElement.appendChild(helpIcon)

      const descriptionText = Helper.appendElement(dialogTrigger.triggerElement, 'span')
      descriptionText.innerText = inputData.description

      description.appendChild(dialogTrigger.triggerElement)

      description.appendChild(dialogTrigger.dialog)
      window.setTimeout(function() {
        Expander.hookup(description)
      }, 100)

      // const helpText = document.createElement('div');
      // helpText.classList.add('description-help');
      // helpText.innerHTML = inputData.descriptionHTML;
      // description.appendChild(helpText)

    } else {
      description.innerText = inputData.description;
    }

    return description;
  }

  function getVariableValue(inputData){
    if (inputData.type == 'select') {
      return getSelectedValue(inputData);
    }
    return Helper.isEmpty(inputData.value) ? inputData.default : inputData.value;
  }

  function getSelectedValue(inputData){
    const value = Helper.isEmpty(inputData.value) ? inputData.default : inputData.value
    const selected = inputData.values.find(x => x.key == value);
    return selected;
  }

  function getValueFromInput(input){
    if (input.type == 'radio' || input.type == 'checkbox'){
      return input.checked ? true : false;
    }
    return input.value;
  }

  return {
    init: init,
    handleErrors: handleErrors,
    clearErrors: clearErrors,
    addField: addField,
    addInputGroup: addInputGroup,
    getValueFromInput: getValueFromInput,
    getVariableValue: getVariableValue,
  }
})();

export default Form;
