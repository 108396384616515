// ErrorHandler.captureException(new Error("data: " + JSON.stringify(errorData)))
import * as Sentry from "@sentry/browser";

const ErrorHandler = (function(){

  function captureException(ex) {
    Sentry.captureException(ex);
  }

  return {
    captureException: captureException
  }
})();

export default ErrorHandler;
