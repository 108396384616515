const Carousel = (function(){
  function init(){
    var carousel = document.querySelector('.carousel_expand')
    if (!carousel) { return; }

    carousel.addEventListener('slide.bs.carousel', function () {
      console.log('hello')
    })
  }

  return {
    init: init
  }
})();

export default Carousel;
