const PageElement = (function(){

  function init(){
    Array.from(document.querySelectorAll('.carousel_expand')).forEach(element => {
      Array.from(element.querySelectorAll('.text_image')).forEach(expandElement => {
        expandElement.removeEventListener('click', onExpandClicked)
        expandElement.addEventListener('click', onExpandClicked)
      })

      element.addEventListener('slide.bs.carousel', function () {
        Array.from(element.querySelectorAll('.text_image')).forEach(expandElement => {
          expandElement.classList.remove('is--expanded')
          expandElement.classList.remove('is--not-expanded')
        });
      })
    })
  }

  function onExpandClicked(event){
    const element = event.currentTarget;
    const isExpaned = element.classList.contains('is--expanded')

    const otherExpandElements = Array.from(element.closest('.carousel_expand').querySelectorAll('.text_image')).forEach(otherElement => {
      otherElement.classList.remove('is--expanded')
      otherElement.classList.remove('is--not-expanded')

      if (!isExpaned){
        otherElement.classList.add('is--not-expanded')
      }
    })

    if (isExpaned == false) {
      element.classList.remove('is--not-expanded')
      element.classList.add('is--expanded')
    }
  }

  return {
    init: init
  }
})();

export default PageElement;
