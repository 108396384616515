// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../polyfills/index'

import '../stylesheets/application.scss'

require("@rails/ujs").start()
require("@rails/activestorage").start()

import '../elements/index'
import '../models/index'
import 'bootstrap'
import '@popperjs/core'

// import '../pwa/network_manager'
// import '../pwa/device_manager'
// import '../pwa/serviceworker_registration'

import LanguageSwitch from "../elements/language_switch";
import Device from "../pwa/device_manager";

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.addEventListener('load', function() {
  LanguageSwitch.init();
  Device.init();
  // if (window.jQuery) {
  //   $(document).ready(function () {
  //     debugger
  //
  //     $('[data-summernote]').trumbowyg()
  //   });
  // }
});
